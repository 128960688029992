<template>
  <div class="list-wrap wrapper">
    <BaseLoad v-if="loading" />
    <div class="list" v-else>
      <h2>已购课程</h2>
      <ListLoad v-if="listLoad && !loading" />
      <div class="course-list" v-if="!listLoad">
        <div v-if="list.length > 0" class="flex">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="course-list-item"
          >
            <div
              class="flex favor-work"
              :class="[item.show ? 'visible' : 'hide']"
            >
              <p
                class="preview flex"
                @click.stop="handlePreview(item.project_id, item.type)"
              >
                <i class="iconfont icon-yulan"></i>
                <span>预览</span>
              </p>
              <span class="slash">/</span>
              <WorkbenchCancel
                v-if="item.issue_status == 1"
                :cType="item.type"
                :cId="item.project_id"
                :cStatus="2"
                :cItem="item"
                :cIndex="index"
                @workbenchCancel="workbenchCancel"
              />
              <Workbench
                v-else
                :cType="item.type"
                :cId="item.project_id"
                :cTitle="item.name"
                :cStatus="1"
                :cItem="item"
                :cIndex="index"
                @handleWorkbench="handleWorkbench"
              />
            </div>
            <li
              :style="{
                background:
                  'url(' + preSrc + item.cover + ') center center no-repeat',
                backgroundSize: 'contain',
              }"
              @mouseenter="handleMouseEnter(index)"
            >
              <p class="ellipsis">{{ item.name }}</p>
            </li>
          </div>
        </div>
      </div>
      <BaseEmpty v-if="!listLoad && !loading && list.length == 0" />
      <!-- 分页 -->
      <div class="pagination-con" v-if="list.length > 0 && total > 6">
        <el-pagination
          :page-size="pageSize"
          :pager-count="5"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- 分页end -->
    </div>
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
import { handlePreview } from "@/tools/GlobalFunction.js";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      listLoad: false,
      loading: true,
      list: [],
      page: 1,
      total: 0,
      pageSize: 6,
    };
  },
  created() {
    let _self = this;
    _self.courseList(_self.page);
  },
  methods: {
    //我的课程
    courseList(page) {
      let _self = this;
      _self.axios
        .post(
          config.url.myCourse,
          _self.$qs.stringify({ page, size: _self.pageSize })
        )
        .then((res) => {
          if (res.code == 200) {
            let list = res.data.list;
            list.forEach((item) => (item.show = false));
            _self.list = list;
            _self.total = res.data.total;
            _self.loading = false;
            _self.listLoad = false;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
            _self.loading = false;
            _self.listLoad = false;
          }
        });
    },
    // hover
    handleMouseEnter(index) {
      let _self = this;
      _self.list.forEach((item) => (item.show = false));
      let item = _self.list[index];
      item.show = true;
      _self.$set(_self.list, index, item);
    },
    // 预览
    handlePreview(id, type) {
      let _self = this;
      if (type == 1) {
        _self.$router.push({
          name: "course-info",
          params: {
            courseId: id,
          },
        });
      }
      if (type == 2) {
        _self.$router.push({
          name: "level-info",
          params: {
            levelId: id,
          },
        });
      }
      if (type == 3) {
        handlePreview(id);
      }
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 改变分页
    handleCurrentChange(val) {
      let _self = this;
      _self.page = val;
      _self.courseList(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #f9f9f9;
  .content {
    background: #fafafa;
  }
}
.con {
  display: flex;
  margin-top: 60px;
  background: #f9f9f9;
  .tab {
    width: 190px;
    height: 440px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 10px;
    background: #fff;
    h3 {
      font-size: 20px;
      font-weight: bold;
      padding: 30px 15px;
      box-sizing: border-box;
    }
    h3::before {
      content: "";
      display: inline-block;
      background: url("../../assets/img/home/pre-icon.png") center center
        no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      margin-right: 6px;
    }
    li {
      padding: 15px 35px;
      box-sizing: border-box;
      // width: 100%;
      border-right: 2px solid #fff;
      margin-bottom: 20px;
    }
    li:hover {
      cursor: pointer;
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
    li.active {
      color: $color-main;
      border-right: 2px solid $color-main;
      background: #fff7f1;
    }
  }
  .list {
    width: 980px;
    padding: 30px;
    box-sizing: border-box;
    background: #fff;
    border-radius: $border-radius-main;
    h2 {
      margin-bottom: 40px;
    }
    .list-item {
      background: #f9f9f9;
      padding: 20px 30px;
      box-sizing: border-box;
      border-radius: $border-radius-main;
      margin-bottom: 30px;
      > p {
        margin-bottom: 20px;
        color: #9f9f9f;
        font-size: 16px;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .work,
  .btn:hover {
    cursor: pointer;
  }
  .favor-work {
    width: 165px;
    margin: 0 0 4px 100px;
    justify-content: flex-end;
    color: $font-color-9f;
    justify-content: flex-start;
    .preview {
      cursor: pointer;
    }
    .icon-yulan {
      color: #34c3f9;
      margin: 3px 6px 0 0;
    }
    .slash {
      margin: 0 10px;
    }
    .favor {
      margin-top: -3px;
    }
    .favor i {
      margin-right: 6px;
    }
    .work {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-right: 10px;
      }
    }

    .icon-bianji1 {
      color: #03cca6;
    }
  }

  // 已购课程
  .course-list {
    display: flex;
    align-items: flex-start;
    position: relative;
    > div,
    ul {
      width: 920px;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .course-list-item {
      margin-right: 40px;
    }
    li {
      width: 275px;
      height: 187px;
      overflow: hidden;
      position: relative;
      margin: 0 20px 20px 0;
      border-radius: $border-radius-main;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
      transition: 0.4s ease-in-out;
      p {
        font-size: 16px;
        z-index: 3;
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 13px 20px;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 0 0 10px 10px;
      }
    }
    .course-list-item:nth-child(3n),
    li:nth-child(3n),
    li:last-of-type {
      margin-right: 0;
    }
    li:hover {
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
      transition: 0.4s;
      cursor: pointer;
    }
  }
}
</style>
