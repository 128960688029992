import '@/axios.js';
import qs from 'qs'; 
import ElementUI, { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 课件预览
export function handlePreview(id, sccuess_back= function(){}) {
  axios
    .post(config.url.coursePreview, qs.stringify({ lesson_id: id }))
    .then((res) => {
      if (res.code == 200) {
        window.location.href = res.data.url;
      } else if(res.code == 500){
        Message({
          type: 'warning',
          message: res.msg
        })
        sccuess_back();
      }else if (res.code != 200 && res.code != 401 && res.code != 500) {
        Message({
          type: 'error',
          message: res.msg
        })
      }
    });
}
//游戏预览
export function handlePreviewCreate(gameId, configId = "", title, _self) {
  if (gameId == 1) {
    _self.$router.push({
      name: "base-game1",
      params: {
        gameId: gameId,
        configId: configId || undefined,
        title: title || "Hidden Pictures Game",
      },
    });
  }
  if (gameId == 2) {
    _self.$router.push({
      name: "base-game2",
      params: {
        gameId: gameId,
        configId: configId || undefined,
        title: title || "what's Missing",
      },
    });
  }
  if (gameId == 3) {
    _self.$router.push({
      name: "base-game3",
      params: {
        gameId: gameId,
        configId: configId || undefined,
        title: title || "幸运大转盘",
      },
    });
  }
  if (gameId == 4) {
    _self.$router.push({
      name: "base-game4",
      params: {
        gameId: gameId,
        configId: configId || undefined,
        title: title || "幸运大转盘",
      },
    });
  }
}
// 从固定数组中选择某几个数
export function getRandomArrayElements(arr, count) {
  var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
  while (i-- > min) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
  }
  return shuffled.slice(min);
}